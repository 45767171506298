@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-spacing: 0; }

body {
  line-height: 1; }

hr {
  display: block;
  width: 100%; }

.color--gold {
  color: #FBD784; }

.efekt, .collection__title {
  transition: all 0.25s ease-in-out; }

.ortala {
  margin-left: auto;
  margin-right: auto; }

.flex, .menu__list, .brands, .shopping {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.bdrs {
  border-radius: 0.25rem; }

.dikeyAbsolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.dikeyFlex {
  display: flex;
  align-items: center; }

.bxs {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out; }
  .bxs:Hover {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1), 0 8px 18px rgba(0, 0, 0, 0.1); }

.bxsh {
  transition: all 0.2s ease-in-out; }
  .bxsh:Hover {
    box-shadow: 0 10px 22px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1); }

.fz15 {
  font-size: 1.5rem; }

.fz14 {
  font-size: 14rem; }

.fz13 {
  font-size: 1.3rem; }

.fz12 {
  font-size: 1.2rem; }

.fz11 {
  font-size: 1.1rem; }

.fz10 {
  font-size: 1rem; }

.fz9 {
  font-size: 0.9rem; }

.fz85 {
  font-size: 0.85rem; }

.fz8 {
  font-size: 0.8rem; }

.fz7 {
  font-size: 0.7rem; }

.bgcover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.fw300 {
  font-weight: 300; }

.fw400 {
  font-weight: 400; }

.fw500 {
  font-weight: 500; }

.fw600 {
  font-weight: 600; }

.fw700 {
  font-weight: 700; }

.fw800 {
  font-weight: 800; }

.mb15 {
  margin-bottom: 15px; }

.mb30 {
  margin-bottom: 30px; }

.mb50 {
  margin-bottom: 50px; }

.mb60 {
  margin-bottom: 60px; }

.mb75 {
  margin-bottom: 75px; }

.mb100 {
  margin-bottom: 100px; }

.mb150 {
  margin-bottom: 150px; }

.mt15 {
  margin-top: 15px; }

.mt30 {
  margin-top: 30px; }

.mt50 {
  margin-top: 50px; }

.mt60 {
  margin-top: 60px; }

.mt75 {
  margin-top: 75px; }

.mt100 {
  margin-top: 100px; }

.mt150 {
  margin-top: 150px; }

.visible-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none; }

.visible-scrollbar::-webkit-scrollbar:vertical {
  width: 2px; }

.visible-scrollbar::-webkit-scrollbar:horizontal {
  height: 2px; }

.visible-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1); }

.visible-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1); }

.scrollbar::-webkit-scrollbar {
  width: 4px; }

.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; }

.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px; }

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #F07400; }

.scrollbar-yatay::-webkit-scrollbar {
  height: 4px; }

.scrollbar-yatay::-webkit-scrollbar-track {
  background: #f1f1f1; }

.scrollbar-yatay::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px; }

.scrollbar-yatay::-webkit-scrollbar-thumb:hover {
  background: #F07400; }

.text-center {
  text-align: center; }

.d-block {
  display: block; }

.d-inline-block {
  display: inline-block; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

html {
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth; }

*,
*:after,
*:before {
  box-sizing: inherit; }

body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.5;
  color: #fff;
  background: #000; }

img {
  max-width: 100%;
  vertical-align: middle; }

button,
input,
select,
textarea {
  font-family: "Montserrat", sans-serif; }

.pos-rel {
  position: relative; }

.slick-slide {
  outline: transparent !important; }
  .slick-slide a {
    outline: transparent !important; }

.noscroll {
  overflow: hidden; }

.tab-panel {
  display: none; }

.tab-active {
  display: block;
  animation: fadeIn 0.5s; }

.tabcontent {
  animation: fadeIn 0.5s; }

.fadeIn {
  animation: fadeIn 0.5s; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none; } }

.form-control[type=file] {
  overflow: hidden; }

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer; }

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.form-control::-webkit-date-and-time-value {
  height: 1.5em; }

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none; } }

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3; }

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none; } }

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3; }

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
::after,
::before {
  box-sizing: inherit; }

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1440px; } }

.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

.row {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  -ms-flex-wrap: wrap; }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  min-height: 1px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  -ms-flex-positive: 1;
  -ms-flex-preferred-size: 0; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  -ms-flex: 0 0 auto; }

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  -ms-flex: 0 0 8.333333%; }

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  -ms-flex: 0 0 16.666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
  -ms-flex: 0 0 25%; }

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  -ms-flex: 0 0 33.333333%; }

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  -ms-flex: 0 0 41.666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  -ms-flex: 0 0 50%; }

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  -ms-flex: 0 0 58.333333%; }

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  -ms-flex: 0 0 66.666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
  -ms-flex: 0 0 75%; }

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
  -ms-flex: 0 0 83.333333%; }

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
  -ms-flex: 0 0 91.666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  -ms-flex: 0 0 100%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex: 0 0 auto; }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%; }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%; }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%; }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%; }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%; }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
    -ms-flex: 0 0 75%; }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%; }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%; } }

@media (min-width: 768px) {
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%; }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%; }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%; }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%; }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%; }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
    -ms-flex: 0 0 75%; }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%; }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%; } }

@media (min-width: 992px) {
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%; }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%; }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%; }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%; }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%; }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
    -ms-flex: 0 0 75%; }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%; }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%; } }

@media (min-width: 1200px) {
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%; }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%; }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%; }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%; }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%; }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
    -ms-flex: 0 0 75%; }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%; }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%; } }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.flex-wrap {
  flex-wrap: wrap; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.justify-content-start {
  justify-content: flex-start !important;
  -ms-flex-pack: start !important; }

.justify-content-end {
  justify-content: flex-end !important;
  -ms-flex-pack: end !important; }

.justify-content-center {
  justify-content: center !important;
  -ms-flex-pack: center !important; }

.justify-content-between {
  justify-content: space-between !important;
  -ms-flex-pack: justify !important; }

.justify-content-around {
  justify-content: space-around !important;
  -ms-flex-pack: distribute !important; }

.align-items-start {
  align-items: flex-start !important;
  -ms-flex-align: start !important; }

.align-items-end {
  align-items: flex-end !important;
  -ms-flex-align: end !important; }

.align-items-center {
  align-items: center !important;
  -ms-flex-align: center !important; }

.m-0 {
  margin: 0 !important; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.title {
  font-size: 3rem;
  font-weight: 600;
  display: inline-block; }
  .title--medium {
    font-size: 2rem; }
  .title--smaller {
    font-size: 1.5rem; }
  .title--500 {
    font-weight: 500; }

@media (max-width: 1200px) {
  .title {
    font-size: 2.2rem; }
    .title--medium {
      font-size: 1.7rem; }
    .title--smaller {
      font-size: 1.2rem; } }

@media (max-width: 992px) {
  .title {
    font-size: 2rem; }
    .title--medium {
      font-size: 1.5rem; }
    .title--smaller {
      font-size: 1.1rem; } }

@media (max-width: 768px) {
  .title {
    font-size: 1.7rem; }
    .title--medium {
      font-size: 1.3rem; }
    .title--smaller {
      font-size: 1.1rem; } }

@media (max-width: 576px) {
  .title {
    font-size: 1.3rem; }
    .title--medium {
      font-size: 1.1rem; }
    .title--smaller {
      font-size: 1rem; } }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none; } }

.btn:hover {
  color: #212529; }

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65; }

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64; }

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }
  .btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43; }

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-info:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37; }

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.btn-mobil,
.mobil {
  display: none; }

.arama-bg,
.mobil-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.arka-active {
  display: block; }

.bi {
  position: relative;
  top: -1px;
  vertical-align: middle; }

.container-fluid {
  padding-right: 5%;
  padding-left: 5%; }

.header {
  position: relative;
  z-index: 3;
  padding-top: 15px;
  height: 122px; }

.menu__item {
  margin: 0 20px; }

.menu__link {
  transition: color 0.25s ease-in-out;
  color: #fff;
  text-decoration: none;
  font-weight: 500; }
  .menu__link:hover {
    color: #FBD784; }

.bayi-girisi {
  transition: color 0.25s ease-in-out;
  color: #fff;
  text-decoration: none;
  font-weight: 500; }
  .bayi-girisi:hover {
    color: #FBD784; }

.hero {
  position: relative;
  z-index: 1;
  margin-top: -122px; }
  .hero .container-fluid {
    position: relative;
    padding-left: 220px; }
  .hero__mid {
    position: absolute;
    top: 25%;
    right: 0;
    left: 0;
    z-index: 3; }
  .hero__title {
    margin-bottom: 30px;
    color: #fff;
    font-weight: 400;
    font-size: 4rem;
    font-family: "Aleo", serif;
    line-height: 1.4; }
  .hero__social {
    position: absolute;
    top: 100px;
    left: 5%;
    width: 30px; }
    .hero__social .social__link {
      display: block;
      margin-bottom: 20px; }

.follow {
  color: #fff;
  font-weight: 500; }
  .follow span {
    position: absolute;
    top: -60px;
    left: -24px;
    display: inline-block;
    width: 70px;
    transform: rotate(90deg); }

.social__link {
  transition: color 0.25s ease-in-out;
  color: #fff; }
  .social__link svg {
    width: 22px;
    height: 22px; }
  .social__link:hover {
    color: #FBD784; }

.hero-img {
  position: relative;
  overflow: hidden; }
  .hero-img--img {
    margin-left: -10%;
    max-width: 120%;
    width: 120%; }
  .hero-img--1 {
    position: relative;
    z-index: 1; }
  .hero-img--2 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    margin-left: -10%;
    max-width: 130%;
    width: 130%; }
  .hero-img--3 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3; }

.link {
  transition: color 0.25s ease-in-out;
  color: #fff;
  text-decoration: none;
  font-weight: 500; }
  .link:hover {
    color: #FBD784; }
  .link--right-icon svg {
    margin-left: 6px; }
  .link--left-icon svg {
    margin-right: 6px; }
  .link--gold {
    color: #FBD784; }
    .link--gold:hover {
      color: #e2b857; }

.small-title {
  position: relative;
  display: block;
  padding-left: 85px;
  color: #FBD784;
  letter-spacing: 3px;
  font-weight: 600;
  font-size: 1rem; }
  .small-title:before {
    position: absolute;
    top: 10px;
    left: 0;
    width: 70px;
    height: 2px;
    background: #FBD784;
    content: ''; }
  .small-title--reverse {
    padding-right: 85px;
    padding-left: 0; }
    .small-title--reverse:before {
      right: 0;
      left: auto; }

.text {
  display: block;
  line-height: 1.8; }

.center-img {
  text-align: center; }

.home-about {
  position: relative;
  z-index: 5;
  margin-top: -120px; }

.home-brands {
  position: relative;
  margin-top: -6%;
  padding-top: 17%;
  padding-bottom: 28%; }
  .home-brands--bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%; }
  .home-brands .container-fluid {
    position: relative;
    z-index: 5; }

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 18%;
  height: 150px;
  background: #000; }
  .brand img {
    max-height: 100%; }

.home-shopping {
  position: relative;
  z-index: 5; }

.shopping .small-title {
  display: inline-block;
  font-size: 0.9rem; }
  .shopping .small-title:before {
    top: 9px; }

.shopping__left {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  width: 49%;
  text-align: right; }

.shopping__info {
  width: 60%; }

.shopping__title {
  display: block;
  margin: 30px 0;
  font-size: 1.7rem; }

.shopping__link {
  transition: color 0.25s ease-in-out;
  color: #fff;
  text-decoration: none; }
  .shopping__link:hover {
    color: #FBD784; }

.shopping__img {
  width: 37%; }
  .shopping__img img {
    width: 100%; }

.shopping__right {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  width: 49%; }

.home-collections {
  margin-top: 170px;
  margin-bottom: 170px; }

.collection {
  padding: 30px; }
  .collection a {
    text-decoration: none; }
  .collection__title {
    display: block;
    margin-bottom: 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid #FBD784;
    color: #FBD784;
    letter-spacing: 4px;
    font-weight: 600;
    font-size: 2rem; }
  .collection:hover .collection__title {
    border-color: #fff;
    color: #fff; }
  .collection img {
    width: 100%; }

.footer--flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 60px 0;
  border-top: 1px solid #FBD784; }

.footer__left {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 50%; }

.footer__map {
  width: 45%; }
  .footer__map iframe {
    height: 100%;
    vertical-align: middle; }

.footer-info {
  margin-right: 6%;
  width: 30%; }
  .footer-info .footer-text {
    display: block;
    margin-top: 20px;
    margin-bottom: 80px; }

.footer-menu {
  width: 30%; }
  .footer-menu__title {
    display: block;
    margin-bottom: 20px;
    color: #FBD784;
    font-weight: 600;
    font-size: 1.5rem; }
  .footer-menu li {
    margin: 24px 0;
    font-weight: 600; }
    .footer-menu li a {
      transition: color 0.25s ease-in-out;
      color: #fff;
      text-decoration: none; }
      .footer-menu li a:hover {
        color: #FBD784; }

.copyright {
  margin-top: 45px;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  font-size: 0.9rem; }

.show-mobile {
  display: none; }

.collection-img {
  margin: 80px 0;
  padding: 30px 0;
  text-align: center; }

@media (max-width: 1450px) {
  .shopping__title {
    font-size: 1.4rem; }
  .home-shopping .container-fluid {
    padding-right: 15px;
    padding-left: 15px; } }

@media (max-width: 1200px) {
  .hero__title {
    font-size: 3rem; }
  .shopping__img {
    margin-bottom: 30px;
    width: 100%; }
    .shopping__img img {
      width: 250px; }
  .shopping__left {
    flex-direction: column-reverse; }
  .shopping__info {
    width: 100%; }
  .footer-menu__title {
    font-size: 1.2rem; }
  .footer-menu li {
    margin: 15px 0; }
  .footer-menu li a {
    font-size: 0.9rem; } }

@media (max-width: 992px) {
  .hero__title {
    font-size: 2.5rem; }
  .hero .container-fluid {
    padding-left: 90px; }
  .hero__social {
    left: 15px; }
  .hero .social__link {
    margin-bottom: 10px; }
    .hero .social__link svg {
      width: 16px;
      height: 16px; }
  .hero__mid {
    top: 40%; }
  .follow span {
    width: 60px;
    font-size: 0.9rem; }
  .menu {
    display: none; }
  .btn-mobil {
    display: inline-block;
    margin-left: 15px; }
  .brands {
    justify-content: center; }
  .brand {
    margin: 1%;
    width: 31%; }
  .home-brands {
    padding-bottom: 15%; }
  .shopping__title {
    margin: 20px 0;
    font-size: 1.2rem; }
  .home-collections {
    margin: 120px 0; }
  .footer__left {
    width: 100%; }
  .footer__map {
    margin-top: 15px;
    width: 100%; }
    .footer__map iframe {
      height: 400px; }
  .copyright {
    margin-top: 15px;
    text-align: center; }
  .show-mobile {
    display: block; }
  .hide-mobile {
    display: none; } }

.btn-mobil {
  padding: 0;
  position: relative;
  top: 6px;
  outline: transparent; }

.arka-active {
  display: block;
  opacity: 1; }

.mobil-nav--icon {
  display: block;
  height: 25px; }

.mobil {
  display: block;
  position: fixed;
  top: 0;
  left: -320px;
  background: #000;
  color: #fff;
  width: 300px;
  height: 100%;
  max-width: calc(100% - 80px);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 9999; }
  .mobil strong {
    font-weight: 500; }
  .mobil .nav-tabs {
    display: flex;
    position: absolute;
    bottom: 0;
    font-weight: 500;
    background: #1b1508;
    width: 100%;
    z-index: 99999; }
    .mobil .nav-tabs li {
      width: 50%;
      text-align: center; }
  .mobil .tab {
    overflow: hidden; }
  .mobil .iletisim-bilgileri {
    padding: 30px;
    line-height: 1.7; }
    .mobil .iletisim-bilgileri .iletisim-box {
      font-size: 0.85rem;
      padding-left: 0; }
    .mobil .iletisim-bilgileri span {
      opacity: 0.7; }
    .mobil .iletisim-bilgileri a {
      color: #fff;
      text-decoration: underline; }
  .mobil .tablinks {
    display: block;
    font-size: 0.8rem;
    cursor: pointer;
    padding: 18px 0 15px;
    border-bottom: 3px solid transparent;
    opacity: 0.6;
    transition: all 0.3s; }
    .mobil .tablinks svg {
      margin-right: 6px; }
    .mobil .tablinks:hover {
      border-color: #FBD784;
      opacity: 0.8; }
    .mobil .tablinks.active {
      border-color: #FBD784;
      opacity: 1; }
  .mobil .tabcontent {
    padding-bottom: 45px;
    overflow-y: scroll;
    height: calc(100vh - 75px); }

.mobil-logo {
  padding: 30px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .mobil-logo img {
    display: block;
    margin: 0 auto;
    width: 160px; }
  .mobil-logo .musteri-girisi {
    display: block;
    font-size: 14px;
    padding: 14px;
    margin: 0 auto;
    border-radius: 32px;
    width: 90%;
    text-decoration: none; }
    .mobil-logo .musteri-girisi i {
      right: 20px; }

.mobil-baslik {
  display: block;
  font-size: 16px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 25px 15px;
  border: 0;
  transition: all 0.3s; }

.mobil-menu-li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .mobil-menu-li .btn-atik {
    display: block;
    padding: 15px 30px;
    width: auto;
    margin: 0 auto !important;
    width: 230px !important;
    margin-top: 30px !important; }

.mobil-ana-menu .mobil-menu-li a {
  width: 100%; }

.mobil-ana-menu .mobil-menu-li i {
  margin-top: 2px;
  float: right; }

.mobil-menu-link {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  padding: 20px 20px 20px 35px;
  transition: all 0.3s;
  text-decoration: none !important; }
  .mobil-menu-link i {
    margin-right: 6px; }
  .mobil-menu-link:focus, .mobil-menu-link:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #FBD784; }
  .mobil-menu-link.active {
    background: rgba(255, 255, 255, 0.1);
    color: #FBD784; }

.mobil-alt-menu {
  display: none;
  background: rgba(255, 255, 255, 0.3);
  padding: 15px 0; }
  .mobil-alt-menu ul {
    padding: 10px 0;
    line-height: 1.4; }
  .mobil-alt-menu a {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8);
    padding: 12px 12px 12px 50px;
    text-decoration: none;
    transition: color 0.25s ease-in-out; }
    .mobil-alt-menu a:Hover {
      color: #FBD784; }
    .mobil-alt-menu a.active {
      color: #FBD784; }

.mobil-active {
  left: 0; }

.mobil-hr {
  background: rgba(255, 255, 255, 0.1);
  margin-left: -50px;
  border: 0;
  height: 1px; }

.mobil-tab {
  height: calc(100vh - 80px);
  overflow-y: scroll; }

.mobil-alt-menu-var {
  display: flex;
  justify-content: space-between;
  align-items: center; }

@media (max-width: 768px) {
  .home-about {
    margin-top: 0; }
    .home-about .container {
      max-width: 100%; }
    .home-about .center-img img {
      height: 200px; }
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px; }
  .logo {
    width: 120px; }
  .bayi-girisi {
    font-size: 0.8rem; }
  .header {
    height: 85px; }
  .hero {
    margin-top: -85px; }
  .hero__title {
    font-size: 2rem; }
  .hero-img img {
    margin-left: -70%;
    max-width: 220%;
    width: 220%; }
  .shopping__left {
    margin-bottom: 30px;
    width: 100%;
    text-align: center; }
  .shopping__right {
    width: 100%;
    text-align: center; }
  .collection {
    padding: 0; }
  .collection__title {
    margin-bottom: 30px;
    font-size: 1.4rem; }
  .home-collections {
    margin: 80px 0; }
  .footer--flex {
    padding: 30px 0; }
  .collection-img {
    margin: 50px 0;
    padding: 0;
    text-align: center; } }

@media (max-width: 576px) {
  .hero__title {
    font-size: 1.8rem; }
  .link {
    font-size: 0.9rem; }
  .hero .container-fluid {
    padding-left: 60px; }
  .brand {
    padding: 0 20px;
    width: 48%;
    height: 120px; }
  .footer-info {
    margin-bottom: 15px;
    width: 100%;
    text-align: center; }
  .footer-menu {
    margin: 15px 0;
    width: 100%;
    text-align: center; }
  .home-about article {
    text-align: center; }
  .home-about .small-title {
    display: inline-block; }
  .mb-5 {
    margin-bottom: 2rem !important; }
  .mt-5 {
    margin-top: 2rem !important; } }
