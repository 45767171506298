@media (max-width:1450px) {
  .shopping__title {
    font-size: 1.4rem;
  }

  .home-shopping {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
@media (max-width:1200px) {
  .hero__title {
    font-size: 3rem;
  }

  .shopping__img {
    margin-bottom: 30px;
    width: 100%;

    img {
      width: 250px;
    }
  }

  .shopping__left {
    flex-direction: column-reverse;
  }

  .shopping__info {
    width: 100%;
  }

  .footer-menu__title {
    font-size: 1.2rem;
  }

  .footer-menu li {
    margin: 15px 0;
  }

  .footer-menu li a {
    font-size: 0.9rem;
  }
}
