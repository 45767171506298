.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;

  &:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
  }
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;

  &:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
  }
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;

  &:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
  }
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;

  &:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2;
  }
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;

  &:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
  }
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;

  &:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
  }
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;

  &:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;

  &:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;

  &:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
  }
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;

  &:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
  }
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;

  &:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
  }
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;

  &:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
