@media (max-width: 576px) {
  .hero__title {
    font-size: 1.8rem;
  }

  .link {
    font-size: 0.9rem;
  }

  .hero .container-fluid {
    padding-left: 60px;
  }

  .brand {
    padding: 0 20px;
    width: 48%;
    height: 120px;
  }

  .footer-info {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }

  .footer-menu {
    margin: 15px 0;
    width: 100%;
    text-align: center;
  }

  .home-about {
    article {
      text-align: center;
    }

    .small-title {
      display: inline-block;
    }
  }

  .mb-5 {
    margin-bottom: 2rem !important;
  }

  .mt-5 {
    margin-top: 2rem !important;
  }
}
