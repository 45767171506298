@media (max-width: 992px) {
  .hero__title {
    font-size: 2.5rem;
  }

  .hero .container-fluid {
    padding-left: 90px;
  }

  .hero {
    &__social {
      left: 15px;
    }

    .social__link {
      margin-bottom: 10px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__mid {
      top: 40%;
    }
  }

  .follow {
    span {
      width: 60px;
      font-size: 0.9rem;
    }
  }

  .menu {
    display: none;
  }

  .btn-mobil {
    display: inline-block;
    margin-left: 15px;
  }

  .brands {
    justify-content: center;
  }

  .brand {
    margin: 1%;
    width: 31%;
  }

  .home-brands {
    padding-bottom: 15%;
  }

  .shopping__title {
    margin: 20px 0;
    font-size: 1.2rem;
  }

  .home-collections {
    margin: 120px 0;
  }

  .footer__left {
    width: 100%;
  }

  .footer__map {
    margin-top: 15px;
    width: 100%;

    iframe {
      height: 400px;
    }
  }

  .copyright {
    margin-top: 15px;
    text-align: center;
  }

  .show-mobile {
    display: block;
  }

  .hide-mobile {
    display: none;
  }
}
