@media (max-width: 768px) {
  .home-about {
    margin-top: 0;

    .container {
      max-width: 100%;
    }

    .center-img {
      img {
        height: 200px;
      }
    }
  }

  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }

  .logo {
    width: 120px;
  }

  .bayi-girisi {
    font-size: 0.8rem;
  }

  .header {
    height: 85px;
  }

  .hero {
    margin-top: -85px;
  }

  .hero__title {
    font-size: 2rem;
  }

  .hero-img {
    img {
      margin-left: -70%;
      max-width: 220%;
      width: 220%;
    }
  }

  .shopping__left {
    margin-bottom: 30px;
    width: 100%;
    text-align: center;
  }

  .shopping__right {
    width: 100%;
    text-align: center;
  }

  .collection {
    padding: 0;
  }

  .collection__title {
    margin-bottom: 30px;
    font-size: 1.4rem;
  }

  .home-collections {
    margin: 80px 0;
  }

  .footer--flex {
    padding: 30px 0;
  }

  .collection-img {
    margin: 50px 0;
    padding: 0;
    text-align: center;
  }
}
