@import 'reset', 'colors', 'mixin', 'global', 'hamburgers', 'bootstrap-grid', 'slick', 'typography', 'buttons';

.btn-mobil,
.mobil {
	display: none;
}

.arama-bg,
.mobil-bg {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 996;
	display: none;
	width: 100%;
	height: 100%;
	background: $renkss;
	transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.arka-active {
	display: block;
}

.bi {
	position: relative;
	top: -1px;
	vertical-align: middle;
}

.container-fluid {
	padding-right: 5%;
	padding-left: 5%;
}

.header {
	position: relative;
	z-index: 3;
	padding-top: 15px;
	height: 122px;
}

.logo {}

.menu {
	&__list {
		@extend .flex;
	}

	&__item {
		margin: 0 20px;
	}

	&__link {
		@include efekt(color);
		color: #fff;
		text-decoration: none;
		font-weight: 500;

		&:hover {
			color: $renk_altin;
		}
	}
}

.bayi-girisi {
	@include efekt(color);
	color: #fff;
	text-decoration: none;
	font-weight: 500;

	&:hover {
		color: $renk_altin;
	}
}

.hero {
	position: relative;
	z-index: 1;
	margin-top: -122px;

	.container-fluid {
		position: relative;
		padding-left: 220px;
	}

	&__mid {
		position: absolute;
		top: 25%;
		right: 0;
		left: 0;
		z-index: 3;
	}

	&__title {
		margin-bottom: 30px;
		color: #fff;
		font-weight: 400;
		font-size: 4rem;
		font-family: $aleo;
		line-height: 1.4;
	}

	&__social {
		position: absolute;
		top: 100px;
		left: 5%;
		width: 30px;

		.social__link {
			display: block;
			margin-bottom: 20px;
		}
	}
}

.follow {
	color: #fff;
	font-weight: 500;

	span {
		position: absolute;
		top: -60px;
		left: -24px;
		display: inline-block;
		width: 70px;
		transform: rotate(90deg);
	}
}

.social {
	&__link {
		@include efekt(color);
		color: #fff;

		svg {
			width: 22px;
			height: 22px;
		}

		&:hover {
			color: $renk_altin;
		}
	}
}

.hero-img {
	position: relative;
	overflow: hidden;

	&--img {
		margin-left: -10%;
		max-width: 120%;
		width: 120%;
	}

	&--1 {
		position: relative;
		z-index: 1;
	}

	&--2 {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
		margin-left: -10%;
		max-width: 130%;
		width: 130%;
	}

	&--3 {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 3;
	}
}

.link {
	@include efekt(color);
	color: #fff;
	text-decoration: none;
	font-weight: 500;

	&:hover {
		color: $renk_altin;
	}

	&--right-icon {
		svg {
			margin-left: 6px;
		}
	}

	&--left-icon {
		svg {
			margin-right: 6px;
		}
	}

	&--gold {
		color: $renk_altin;

		&:hover {
			color: $renk_altin_dk;
		}
	}
}

.small-title {
	position: relative;
	display: block;
	padding-left: 85px;
	color: $renk_altin;
	letter-spacing: 3px;
	font-weight: 600;
	font-size: 1rem;

	&:before {
		position: absolute;
		top: 10px;
		left: 0;
		width: 70px;
		height: 2px;
		background: $renk_altin;
		content: '';
	}

	&--reverse {
		padding-right: 85px;
		padding-left: 0;

		&:before {
			right: 0;
			left: auto;
		}
	}
}

.text {
	display: block;
	line-height: 1.8;
}

.center-img {
	text-align: center;
}

.home-about {
	position: relative;
	z-index: 5;
	margin-top: -120px;
}

.home-brands {
	position: relative;
	margin-top: -6%;
	padding-top: 17%;
	padding-bottom: 28%;

	&--bg {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
	}

	.container-fluid {
		position: relative;
		z-index: 5;
	}
}

.brands {
	@extend .flex;
}

.brand {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;
	width: 18%;
	height: 150px;
	background: #000;

	img{
		max-height: 100%;
	}
}

.home-shopping {
	position: relative;
	z-index: 5;
}

.shopping {
	@extend .flex;

	.small-title {
		display: inline-block;
		font-size: 0.9rem;

		&:before {
			top: 9px;
		}
	}

	&__left {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: space-between;
		width: 49%;
		text-align: right;
	}

	&__info {
		width: 60%;
	}

	&__title {
		display: block;
		margin: 30px 0;
		font-size: 1.7rem;
	}

	&__link {
		@include efekt(color);
		color: #fff;
		text-decoration: none;

		&:hover {
			color: $renk_altin;
		}
	}

	&__img {
		width: 37%;

		img {
			width: 100%;
		}
	}

	&__right {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: space-between;
		width: 49%;
	}
}

.home-collections {
	margin-top: 170px;
	margin-bottom: 170px;
}

.collection {
	padding: 30px;

	a {
		text-decoration: none;
	}

	&__title {
		@extend .efekt;
		display: block;
		margin-bottom: 40px;
		padding-bottom: 10px;
		border-bottom: 1px solid $renk_altin;
		color: $renk_altin;
		letter-spacing: 4px;
		font-weight: 600;
		font-size: 2rem;
	}

	&:hover {
		.collection__title {
			border-color: #fff;
			color: #fff;
		}
	}

	img {
		width: 100%;
	}
}

.footer {
	&--flex {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 60px 0;
		border-top: 1px solid $renk_altin;
	}

	&__left {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 50%;
	}

	&__map {
		width: 45%;

		iframe {
			height: 100%;
			vertical-align: middle;
		}
	}
}

.footer-info {
	margin-right: 6%;
	width: 30%;

	.footer-text {
		display: block;
		margin-top: 20px;
		margin-bottom: 80px;
	}
}

.footer-menu {
	width: 30%;

	&__title {
		display: block;
		margin-bottom: 20px;
		color: $renk_altin;
		font-weight: 600;
		font-size: 1.5rem;
	}

	li {
		margin: 24px 0;
		font-weight: 600;

		a {
			@include efekt(color);
			color: #fff;
			text-decoration: none;

			&:hover {
				color: $renk_altin;
			}
		}
	}
}

.copyright {
	margin-top: 45px;
	width: 100%;
	color: rgba(#ffffff, 0.5);
	font-weight: 600;
	font-size: 0.9rem;
}

.show-mobile{
	display: none;
}

.collection-img {
	margin: 80px 0;
	padding: 30px 0;
	text-align: center;
}
@import 'style1200', 'style992', 'mobil-nav', 'style768', 'style576';
