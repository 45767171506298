.title {
  font-size: 3rem;
  font-weight: 600;
  display: inline-block;

  &--medium {
    font-size: 2rem;
  }

  &--smaller {
    font-size: 1.5rem;
  }

  &--500{
    font-weight: 500;
  }
}
@media (max-width:1200px) {
  .title {
    font-size: 2.2rem;

    &--medium {
      font-size: 1.7rem;
    }

    &--smaller {
      font-size: 1.2rem;
    }
  }
}
@media (max-width: 992px) {
  .title {
    font-size: 2rem;

    &--medium {
      font-size: 1.5rem;
    }

    &--smaller {
      font-size: 1.1rem;
    }
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 1.7rem;

    &--medium {
      font-size: 1.3rem;
    }

    &--smaller {
      font-size: 1.1rem;
    }
  }
}
@media (max-width: 576px) {
  .title {
    font-size: 1.3rem;

    &--medium {
      font-size: 1.1rem;
    }

    &--smaller {
      font-size: 1rem;
    }
  }
}
